import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTNmZGYyYTVhNjE4MzkyMDY5MjcyZmZjNjYwNzQ4YmI4YzNlYTUxMmYyYzZiOWE3NGQ3OGUyZjAwMGYwMzQzYzM1ZWZlNGYwNWY4ZGY4YzAiLCJpYXQiOjE3MjY4MTY4NjEuNzA0NzM3LCJuYmYiOjE3MjY4MTY4NjEuNzA0NzQyLCJleHAiOjE3MjY5MDMyNjEuNjg4MDA1LCJzdWIiOiI2MyIsInNjb3BlcyI6W119.SNDQfVBTWnmpwAYFNpIv18PSAXh6rXI6RZfhPx2D6J9aqolaX7tBd4nsLz37uwD7Nax4J7RkurW46E0DntB48ZU7zBWeVjwQo9SIVEYI4UJDK8U9AqUD3UWCGBXUuLK2w9nn1P__TTFIYmeBxakVk6WlLa7b6OeB_7ga0R6Ajn4dwXT82nJ-zzdOLFOL3Qoi72wFxZ5H25K4NIVbG5VM2oxbZlA96xk8ulv-VJY2ehU2olhDwo-1FOPB5b3K0BwcHQYxkCwbov2BGfTV5-l4isD2DeWyVcz6PUQRv3EYzk8hVxFatIifOEDRWZ-c62vdUDk5qneD8oXvbLBAxwA8ZvOAcZojVjDPoG7RiC-ohRUNryHfU42C4GMXoeG9Ssvd5jWSLfeP9HDDPCDbZE8h83HjH9pPz__oloWI_FuJAHxFC_cDVt5RoXimrCw-XB30zP-zF6CPbXvQ4IdnImSl3r7F-WiA7jjdU9ooWrOpr58pa1CIjindgecx_CvWMrvh-AVQEyVuhR0ndAE0tzMWU_7phwTfl1-H4mT4nc9H8Wjbpr6eQg1qc7AJ-CrwYi7DcNXSIa3rBd83VWApXddOWvIzln3shaAAiqqhkMoby88pD4l90JMgEPFqfLfxLIKLgxrPgvi2na-9vkLB53ilFL0gNQ9pG0ncpv2cbEWoZtU',
    'Access-api-key': "eyJpdiI6ImpzZlpmejVreDhvTmdWeDBiSDYrYlE9PSIsInZhbHVlIjoiQ2FqbFo3ZWs3L2xwbWNsRGs1MEI4Zz09IiwibWFjIjoiMjAwOGVlYTM1Y2IzNGMyMmM3MjBmNjc1Njc4NTA1Y2RhMmQ0ZGM5ZTkzZmQwZjhmNWViNWNkMmRlYmQ2MDdhYSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IjhoNUc0VlY1UXR4amd2dTVxRXZxTmc9PSIsInZhbHVlIjoiTk41Q1lHV1RVMG1PNHQ4dEMvK3hpZz09IiwibWFjIjoiNDA4YjJjNWY4ZGE3MTk0ZTFlZGIxNTM3NTYxYmQyNjNlZDczOTRhM2YzNGY1YmI4ZWExODk1NTgxMzY0YzVlNCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}